import Vue from 'vue'
import VueRouter from 'vue-router'

import { Layout } from '@/layouts/index'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/index',
    name: 'index',
    component: Layout,
    hidden: true,
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    },
    children: [
      {
        path: 'main',
        name: 'Main',
        component: () => import('@/views/authority/home'),
        meta: {
          title: '后台首页',
          affix: true,
          cacheable: true
        }
      }
    ]
  },
  /**
   * 角色相关
   * type: role
   */

// 会员管理。。。

  {
    path: '/role/memberManagement',
    name: 'memberManagement',
    type: 'role',
    component: Layout,
    hidden: false,
    meta: {
      title: '会员管理',
      icon: 'dashboard'
    },
    children: [
      // 已有
      {
        path: '/role/memberManagement/memberList',
        name: 'memberList',
        component: () => import('@/views/role/memberManagement/memberList'),
        meta: {
          title: '会员列表',
          icon: 'el-icon-user',
          cacheable: true,

        }
      },
      // {
      //   path: '/role/memberManagement/memberLevel',
      //   name: 'memberLevel',
      //   component: () => import('@/views/role/memberManagement/memberLevel'),
      //   meta: {
      //     title: '会员等级',
      //     cacheable: true
      //   }
      // },

      {
        path: '/role/memberManagement/doubleOrder',
        name: 'doubleOrder',
        component: () => import('@/views/role/memberManagement/doubleOrder'),
        meta: {
          title: '连单',
          icon: 'el-icon-link',
          cacheable: true
        }
      },
      {
        path: '/role/memberManagement/bankCard',
        name: 'bankCard',
        component: () => import('@/views/role/memberManagement/bankCard'),
        meta: {
          title: '银行卡',
          icon: 'el-icon-bank-card',
          cacheable: true
        }
      },
      {
        path: '/role/memberManagement/linkView',
        name: 'linkView',
        component: () => import('@/views/role/memberManagement/linkView'),
        meta: {
          title: '关系链查看',
          icon: 'el-icon-share',
          cacheable: true
        }
      },
      // {
      //   path: '/role/memberManagement/memberEnterForm',
      //   name: 'memberEnterForm',
      //   component: () => import('@/views/role/memberManagement/memberEnterForm'),
      //   meta: {
      //     title: '会员入职单',
      //     cacheable: true
      //   }
      // }
    ]
  },

 // 代理管理
  {
    path: '/role/agencyManagement',
    name: 'agencyManagement',
    type: 'role',
    component: Layout,
    hidden: false,
    meta: {
      title: '代理管理',
      icon: 'dashboard'
    },
    children: [
      {
        path: '/role/agencyManagement/agencyList',
        name: 'agencyList',
        component: () => import('@/views/role/agencyManagement/agencyList'),
        meta: {
          title: '代理列表',
          affix: true,
          icon: 'el-icon-link',
          cacheable: true
        }
      },
      {
        path: '/role/agencyManagement/agencyListLevel',
        name: 'agencyListLevel',
        component: () => import('@/views/role/agencyManagement/agencyListLevel'),
        hidden: true,
        meta: {
          title: '二级代理',
          affix: true,
          icon: 'el-icon-link',
          cacheable: true
        }
      },
// 无此页面
      {
        path: '/authority/department',
        name: 'department',
        component: () => import('@/views/role/agencyManagement/agencyList1'),
        meta: {
          title: '代理报表',
          icon: 'el-icon-s-data',
          cacheable: true
        }
      }
    ]
  },

  // 客服管理
  {
    path: '/role/customerManagement',
    name: 'customerManagement',
    type: 'role',
    component: Layout,
    hidden: false,
    meta: {
      title: '客服管理',
      icon: 'dashboard'
    },
    children: [
      {
        path: '/role/customerManagement/customerList',
        name: 'customerList',
        component: () => import('@/views/role/customerManagement/customerList'),
        meta: {
          title: '客服列表',
          icon: 'el-icon-s-custom',
          affix: true,
          cacheable: true
        }
      },
      {
        path: '/role/customerManagement/customerCode',
        name: 'customerCode',
        component: () => import('@/views/role/customerManagement/customerCode'),
        meta: {
          title: '客服代码',
          icon: 'el-icon-edit-outline',
          affix: true,
          cacheable: true
        }
      }
    ]
  },



  /**
   * 交易
   * type: trad
   *
   * */
  // 交易管理
  {
    path: '/trad/tradManagement',
    name: 'tradManagement',
    type: 'trad',
    component: Layout,
    hidden: false,
    meta: {
      title: '交易管理',
      icon: 'dashboard'
    },
    children: [
      // 已有
      {
        path: '/trad/tradManagement/orderList',
        name: 'orderList',
        component: () => import('@/views/trad/tradManagement/orderList'),
        meta: {
          title: '订单列表',
          icon: 'el-icon-s-order',
          cacheable: true
        }
      },
      // 已有
      {
        path: '/trad/tradManagement/rechargeManagement',
        name: 'rechargeManagement',
        component: () => import('@/views/trad/tradManagement/rechargeManagement'),
        meta: {
          title: '充值管理',
          icon: 'el-icon-money',
          cacheable: true
        }
      },
      // 已有
      {
        path: '/trad/tradManagement/withdrawManagement',
        name: 'withdrawManagement',
        component: () => import('@/views/trad/tradManagement/withdrawManagement'),
        meta: {
          title: '提现管理',
          icon: 'el-icon-s-cooperation',
          cacheable: true
        }
      },

      {
        path: '/trad/tradManagement/tradControl',
        name: 'tradControl',
        component: () => import('@/views/trad/tradManagement/tradControl'),
        meta: {
          title: '交易控制',
          icon: 'el-icon-s-operation',
          cacheable: true
        }
      }
    ]
  },
  // 商品管理
  {
    path: '/trad/shopManagement',
    name: 'shopManagement',
    type: 'trad',
    component: Layout,
    hidden: false,
    meta: {
      title: '商品管理',
      icon: 'dashboard'
    },
    children: [
      {
        path: '/trad/shopManagement/shopList',
        name: 'shopList',
        component: () => import('@/views/trad/shopManagement/shopList'),
        meta: {
          title: '商品列表',
          icon: 'el-icon-s-order',
          cacheable: true
        }
      },
      {
        path: '/trad/shopManagement/shopClass',
        name: 'shopClass',
        component: () => import('@/views/trad/shopManagement/shopClass'),
        meta: {
          title: '商品分类',
          icon: 'el-icon-s-goods',
          cacheable: true
        }
      }
    ]
  },
  // 利息宝
  // {
  //   path: '/trad/treasureOfInterest',
  //   name: 'treasureOfInterest',
  //   type: 'trad',
  //   component: Layout,
  //   hidden: false,
  //   meta: {
  //     title: '利息宝',
  //     icon: 'dashboard'
  //   },
  //   children: [
  //     {
  //       path: '/trad/treasureOfInterest/interestOption',
  //       name: 'interestOption',
  //       component: () => import('@/views/trad/treasureOfInterest/interestOption'),
  //       meta: {
  //         title: '利息宝选项',
  //         cacheable: true
  //       }
  //     },
  //     {
  //       path: '/trad/treasureOfInterest/interestRecord',
  //       name: 'interestRecord',
  //       component: () => import('@/views/trad/treasureOfInterest/interestRecord'),
  //       meta: {
  //         title: '利息宝记录',
  //         cacheable: true
  //       }
  //     }
  //   ]
  // },





  /**
   * 帮助中心
   * type: help
   *
   *
   * */

// 首页文本
  {
    path: '/help/main',
    name: 'main',
    type: 'help',
    component: Layout,
    hidden: false,
    meta: {
      title: '首页文本',
      icon: 'dashboard'
    },
    children: [

      {
        path: '/help/main/publicManagement',
        name: 'publicManagement',
        component: () => import('@/views/help/main/publicManagement'),
        meta: {
          title: '公告管理',
          icon: 'el-icon-position',
          cacheable: true
        }
      },
      {
        path: '/help/main/homeCarousel',
        name: 'homeCarousel',
        component: () => import('@/views/help/main/homeCarousel'),
        meta: {
          title: '首页轮播图',
          icon: 'el-icon-picture',
          cacheable: true
        }
      },

      {
        path: '/help/main/homeText',
        name: 'homeText',
        component: () => import('@/views/help/main/homeText'),
        meta: {
          title: '首页文本',
          icon: 'el-icon-tickets',
          cacheable: true
        }
      },
      {
        path: '/help/main/dataReport',
        name: 'dataReport',
        component: () => import('@/views/help/main/dataReport'),
        meta: {
          title: '数据报表',
          icon: 'el-icon-s-data',
          cacheable: true
        }
      }
    ]
  },





  /**
   * 系统管理
   * type: system
   *
   *
   * */

// 系统配置
  {
    path: '/system/systemConfiguration',
    name: 'systemConfiguration',
    type: 'system',
    component: Layout,
    hidden: false,
    meta: {
      title: '系统配置',
      icon: 'dashboard'
    },
    children: [

      {
        path: '/system/systemConfiguration/systemParams',
        name: 'systemParams',
        component: () => import('@/views/system/systemConfiguration/systemParams'),
        meta: {
          title: '系统参数配置',
          icon: 'el-icon-setting',
          cacheable: true
        }
      },
      {
        path: '/system/systemConfiguration/systemMenu',
        name: 'systemMenu',
        component: () => import('@/views/system/systemConfiguration/systemMenu'),
        meta: {
          title: '系统菜单管理',
          icon: 'el-icon-menu',
          cacheable: true
        }
      },

      {
        path: '/system/systemConfiguration/paymentMethod',
        name: 'paymentMethod',
        component: () => import('@/views/system/systemConfiguration/paymentMethod'),
        meta: {
          title: '支付方式管理',
          icon: 'el-icon-money',
          cacheable: true
        }
      },
      {
        path: '/system/systemConfiguration/systemLog',
        name: 'systemLog',
        component: () => import('@/views/system/systemConfiguration/systemLog'),
        meta: {
          title: '系统日志管理',
          icon: 'el-icon-document',
          cacheable: true
        }
      }
    ]
  },
  // 权限管理
  {
    path: '/authority',
    name: 'authority',
    type: 'system',
    component: Layout,
    hidden: false,
    meta: {
      title: '权限管理',
      icon: 'dashboard'
    },
    children: [

      {
        path: '/system/authorityManagement/accessAuthority',
        name: 'accessAuthority',
        component: () => import('@/views/system/authorityManagement/accessAuthority'),
        meta: {
          title: '访问权限管理',
          icon: 'el-icon-unlock',
          cacheable: true
        }
      },
      {
        path: '/system/authorityManagement/systemUser',
        name: 'systemUser',
        component: () => import('@/views/system/authorityManagement/systemUser'),
        meta: {
          title: '系统用户管理',
          icon: 'el-icon-s-custom',
          cacheable: true
        }
      }
    ]
  },





  /**
   * 商城
   * type: shop
   *
   *
   * */

  // 权限管理
  {
    path: '/authority',
    name: 'authority',
    type: 'shop',
    component: Layout,
    hidden: false,
    meta: {
      title: '商城管理',
      icon: 'dashboard'
    },
    children: [

      {
        path: '/trad/shopManagement/shopClass',
        name: 'shopClass',
        component: () => import('@/views/trad/shopManagement/shopClass'),
        meta: {
          title: '商品分类',
          icon: 'el-icon-s-goods',
          cacheable: true
        }
      },
      {
        path: '/trad/shopManagement/shopList',
        name: 'shopList',
        component: () => import('@/views/trad/shopManagement/shopList'),
        meta: {
          title: '商品列表',
          icon: 'el-icon-s-order',
          cacheable: true
        }
      }
    ]
  },
]

const createRouter = () => new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = createRouter()
// 动态添加路由的函数
function addDynamicRoute(route) {
  const { path, component } = route;
  router.addRoute({ path, name, component });
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
