
<!---->
<template>
  <div>
  <div v-if="notShow">无权限</div>
  <div class="main-container" style="background: #fff;overflow: hidden" v-else>

    <el-button
      type="primary"
      @click="add"
      size="mini"
      style="margin: 10px; float: right">添加商品分类</el-button>
    <div style="margin-top: 50px;">
    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">

          <!--排序-->
          <el-table-column label="分类ID" prop="id" align="center" ></el-table-column>

          <el-table-column  label="分类名称" prop="goodsType" align="center"></el-table-column>
          <el-table-column  label="比例" prop="goodsRadio" align="center"></el-table-column>
          <el-table-column  label="简介" min-width="200" prop="brief" align="center"></el-table-column>
          <el-table-column  label="添加时间" min-width="150" prop="createTime" align="center"></el-table-column>
          <el-table-column  label="操作" min-width="150" prop="" align="center">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" class="button btnGreen" size="mini" >编辑</el-button>
              <el-button @click="deleteClass(scope.row)" class="button" size="mini" type="danger">删除</el-button>
            </template>
          </el-table-column>


          <!--操作-->
          <!--<el-table-column label="操作" fixed="right" align="center" width="200">-->
          <!--  <template slot-scope="scope">-->
          <!--    <el-button class="button" size="mini" type="primary"></el-button>-->
          <!--  </template>-->
          <!--</el-table-column>-->
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    </TableBody>
    </div>

    <!--新增商品-->
    <el-dialog
      :title="type =='add' ? '新增分类' : '编辑分类'"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-form class="form-style" ref="form" :model="formAdd" label-width="100px">
        <el-form-item label="分类名称">
          <el-input v-model="formAdd.goodsType" placeholder="请输入分类名称"></el-input>
        </el-form-item>

        <el-form-item label="佣金比例(不要设置百分比直接写0.01)">
          <el-input v-model="formAdd.goodsRadio" placeholder="请输入佣金比例"></el-input>
        </el-form-item>

        <el-form-item label="分类简介">
          <el-input v-model="formAdd.brief" placeholder="请输入分类简介"></el-input>
        </el-form-item>

        <el-form-item label="最低金额限制">
          <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="formAdd.price" placeholder="请输入金额"></el-input>
        </el-form-item>

        <el-form-item label="分类logo(不用修改)">
          <el-image
            style="width: 100px; height: 100px"
           ></el-image>

        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirm">确 定</el-button>
  </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
import {goodsTypeList, addGoodsType, updateGoodsType, deleteGoodsType} from '../../../api/tradApi'
import TableMixin from '@/mixins/TableMixin'

export default {
  name: 'shopClass',
  mixins: [TableMixin],
  data() {
    return {
      notShow: false,
      tableLoading: false,
      dialogVisible: false,
      tableData: [],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 10
      },
      total: 0,
      type: '',
      formAdd: {
        goodsType: '',
        goodsRadio: '',
        brief: '',
        price: ''
      }

    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    this.getTableList()
  },
  methods: {
    getTableList() {
      const data = {...this.pagination}
      goodsTypeList(data).then(res => {
        if (res.data.status === 0) {
          this.tableData = res.data.result
          this.total = res.data.total
        }
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },
    // 添加商品点击确定
    confirm() {
      if (this.type === 'add') {
        this.addSubmit()
      } else {
        this.editSubmit()
      }
    },
    add() {
      this.dialogVisible = true;
      this.type = 'add'
      this.formAdd = {}
    },
    //新增商品
    addSubmit() {
      addGoodsType({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    },

    //编辑商品
    editSubmit() {
      updateGoodsType({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    },
    edit(row) {
      this.type = 'edit'
      this.formAdd = JSON.parse(JSON.stringify(row))
      this.formAdd.id = row.id
      this.dialogVisible = true
    },
    deleteClass(row) {
      deleteGoodsType({id: row.id}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    },
  }

}
</script>

<style lang="scss" scoped>
.btnGreen {
  background: #009688;
  color: white
}
</style>

