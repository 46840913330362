
<!--关系链查看-->
<template>
  <div class="main-container">
    <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="form"
             class="demo-form-inline">

      <el-form-item label="手机号">
        <el-input v-model="form.value" placeholder="手机号"></el-input>
      </el-form-item>

      <el-form-item label="UID">
        <el-input v-model="form.value" placeholder="UID"></el-input>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">
          <!--选择-->
          <el-table-column type="selection" width="45"/>
          <!--排序-->
          <el-table-column label="" type="index" width="50"></el-table-column>

          <el-table-column  label="" prop="" align="center"></el-table-column>

          <!--操作-->
          <el-table-column label="操作" fixed="right" align="center" width="200">
            <template slot-scope="scope">
              <el-button class="button" size="mini" type="primary"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>

    </TableBody>
  </div>
</template>

<script>
import TableMixin from '@/mixins/TableMixin'

export default {
  name: 'linkView',
  mixins: [TableMixin],
  data() {
    return {
      tableLoading: false,
      tableData: [],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 10,
        total: ''
      }

    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList() {},
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
    },
    handleCurrentChange(page) {
      this.pagination.page = page
    }
  }

}
</script>

<style lang="scss" scoped>
</style>

