// import request from './request.js';
import fetch from '../utils/fetch.js'

// 会员列表
export function memberList(query) {
  return fetch({
    url: '/api/member/list',
    method: 'post',
    data: query
  })
}

// 抢单管理
export function grabList(query) {
  return fetch({
    url: '/api/grab/list',
    method: 'post',
    data: query
  })
}
// 抢单开关
export function grabSwitch(query) {
  return fetch({
    url: '/api/grab/grabSwitch',
    method: 'post',
    data: query
  })
}

// 派单
export function dispatchOrder(query) {
  return fetch({
    url: '/api/dispatch/order',
    method: 'post',
    data: query
  })
}
// 查询已派单
export function dispatchList(query) {
  return fetch({
    url: '/api/dispatch/list',
    method: 'post',
    data: query
  })
}
// 充值管理
export function topupList(query) {
  return fetch({
    url: '/api/topup/list',
    method: 'post',
    data: query
  })
}
// 充值审核

export function topupVerifyt(query) {
  return fetch({
    url: '/api/topup/verify',
    method: 'post',
    data: query
  })
}
// 提现列表
export function withdrawalList(query) {
  return fetch({
    url: '/api/withdrawal/list',
    method: 'post',
    data: query
  })
}
// 提现审核
export function withdrawalverify(query) {
  return fetch({
    url: '/api/withdrawal/verify',
    method: 'post',
    data: query
  })
}

// 会员编辑
export function memberEdit(query) {
  return fetch({
    url: '/api/member/edit',
    method: 'post',
    data: query
  })
}

// 登录
export function login(query) {
  return fetch({
    url: '/api/login',
    method: 'post',
    data: query
  })
}

// 修改密码
export function revisePwd(query) {
  return fetch({
    url: '/api/revisePwd',
    method: 'post',
    data: query
  })
}

// 首页
export function indexReport(query) {
  return fetch({
    url: '/api/data/indexReport',
    method: 'get',
    data: query
  })
}
export function labelReport(query) {
  return fetch({
    url: '/api/data/labelReport',
    method: 'post',
    data: query
  })
}
export function dispatchDelete(query) {
  return fetch({
    url: '/api/dispatch/delete',
    method: 'post',
    data: query
  })
}
export function agentReport(query) {
  return fetch({
    url: '/api/data/agentReport',
    method: 'post',
    data: query
  })
}
export function agentList(query) {
  return fetch({
    url: '/api/data/agentList',
    method: 'get',
    data: query
  })
}
// 查看所属代理
export function levelAgent(query) {
  return fetch({
    url: '/api/agent/levelAgent',
    method: 'get',
    data: query
  })
}
