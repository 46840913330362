import { resetRouter } from '@/router/index'
import Cookies from 'js-cookie'

const userInfoString = localStorage.getItem('userInfo')
// const userInfo = JSON.parse(userInfoString || '{}') // todo
const userInfo = userInfoString
const state = {
  userId: userInfo ? userInfo.userId : '',
  userName: userInfo ? userInfo.userName : '',
  userNickName: userInfo ? userInfo.userNickName : '',
  roleId: userInfo ? userInfo.userId : '',
  roles: userInfo ? userInfo.roles : [],
  avatar: userInfo ? userInfo.avatar : '',
  // token: userInfo?.token || '',
  token: '',
  account: '',
  userMenu: [
      {
        menuUrl: '/authority',
        menuName: '会员管理',
        icon: 'system',
        tip: 'new',
        children: [
          {
            menuUrl: '/authority/department',
            menuName: '会员列表',
            tip: 'new'
          },
          {
            menuUrl: '/authority/user',
            menuName: '抢单管理',
            tip: 'circle'
          }
          // {
          //   menuUrl: '/authority/role',
          //   menuName: '角色管理',
          //   tip: '12'
          // },
          // {
          //   menuUrl: '/authority/menu',
          //   menuName: '菜单管理'
          // }
        ]
      },
      {
        menuUrl: '/list',
        menuName: '业务员管理',
        icon: 'list',
        children: [
          {
            menuUrl: '/list/table',
            menuName: '业务员列表'
          },
          {
            menuUrl: '/list/table-with-search',
            menuName: '账户明细'
          },
          {
            menuUrl: '/list/table-custom',
            menuName: '我的提现'
          }
          // {
          //   menuUrl: '/list/grid-list',
          //   menuName: '卡片列表'
          // }
        ]
      }
    ]
}



const getters = {
  getAvatar(state) {
    return state.avatar || require('@/assets/img_avatar_example.gif')
  },
  getUserName(state) {
    return state.userName
  },
  getToken(state) {
    return state.token
  },
  getAccount(state) {
    return state.account
  },
  getNickName(state) {
    return state.userNickName
  },
  getRoles(state) {
    return state.roles
  },
  getUserMenu(state) {
    return state.userMenu
  }
}

const actions = {
  saveUserInfo({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      try {
        commit('SAVE_USER_INFO', userInfo)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setUserMenu({ commit }, userMenu) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_USER_MENU', userMenu)
        localStorage.setItem('token', token);
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setUserToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_TOKEN', token)
        localStorage.setItem('token', token);
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  setAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_ACCOUNT', account)
        localStorage.setItem('account', account);
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      try {
        dispatch('router/delAllRoute', null, { root: true })
        commit('LOGOUT')
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
}

const mutations = {
      // 修改token，并将token存入localStorage
    SET_TOKEN(state,token) {
        state.token = token;
        localStorage.setItem('token', token);
      },
      SET_ACCOUNT(state, account) {
        state.account = account;
        localStorage.setItem('account', account);

      },
  SAVE_USER_INFO(state, userInfo) {
    state.userId = userInfo.userId
    state.userName = userInfo.userName
    state.userNickName = userInfo.userNickName
    state.avatar = userInfo.avatar
    state.roleId = userInfo.roleId
    state.roles = userInfo.roles
    state.token = userInfo.token
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    Cookies.set('admin-token', userInfo.token, {
      expires: 1
    })
  },
  SET_USER_MENU(state,userMenu) {
    state.userMenu = userMenu
  },
  LOGOUT(state) {
    // 这里只是在本地模拟删除了用户信息，在真实场景下需要 调后台登出接口 来真正实现登出功能
    state.userId = ''
    state.userName = ''
    state.userNickName = ''
    state.roleId = ''
    state.roles = []
    state.avatar = ''
    state.token = ''
    resetRouter()
    Cookies.remove('admin-token')
    localStorage.removeItem('userInfo')


    state.token = ''
    localStorage.removeItem('token')
    state.account = ''
    localStorage.removeItem('account')

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
