
<!---->
<template>
  <div class="main-container" style="background: #fff;overflow: hidden">
    <div style="display: flex;justify-content: right;float: right;margin-top: 13px;">
      <el-upload
        :on-success="uploadSuccess"
        class="upload-demo"
        ref="upload"
        :action="`https://amazonshoplink.icu/api/amazon/file/upload`"
        :file-list="fileList"
      >
        <el-button
          type="primary"
          size="mini"
          style="margin: 10px; float: right">批量导入</el-button>
      </el-upload>

      <el-button
        type="primary"
        @click="add"
        size="mini"
        style="margin: 10px; float: right">添加支付方式</el-button>
    </div>

    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">
      <!--输入框-->
      <el-form-item label="手机号码">
        <el-input v-model="form.account" placeholder="手机号码"></el-input>
      </el-form-item>
      <el-form-item label="usdt">
        <el-input v-model="form.usdt" placeholder="usdt"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-top: 50px;">
    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">
          <el-table-column  label="序号" prop="id" align="center"></el-table-column>
          <el-table-column  label="usdt" prop="usdt" align="center"></el-table-column>
          <el-table-column  label="名称" prop="name" align="center"></el-table-column>
          <el-table-column  label="标识" prop="type" align="center"></el-table-column>
          <el-table-column  label="最小金额" prop="minBalance" align="center"></el-table-column>
          <el-table-column  label="最大金额" prop="maxBalance" align="center"></el-table-column>
          <el-table-column  label="手续费" prop="serviceCharge" align="center"></el-table-column>
          <el-table-column  label="绑定手机号" prop="account" align="center"></el-table-column>
          <el-table-column  label="状态" prop="" align="center">
            <template slot-scope="scope">
              <span :class="{'on': scope.row.status == 0, 'off': scope.row.status == -1}">{{scope.row.status == 0 ? '使用中' : '已禁用'}}</span>
            </template>
          </el-table-column>
          <el-table-column  label="代付状态" prop="" align="center">
            <template slot-scope="scope">
              <span :class="{'on': scope.row.payAnother == 0, 'off': scope.row.payAnother == -1}">{{scope.row.payAnother == 0 ? '使用中' : '已禁用'}}</span>
            </template>
          </el-table-column>

          <!--操作-->
          <el-table-column label="操作" fixed="right" align="center" width="300">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" class="button button-small" size="mini" type="primary">编辑</el-button>
              <el-button @click="updateStatus(scope.row)" class="button button-small" size="mini" type="primary">{{scope.row.status == 0 ? '禁用代收' : '启用代收'}}</el-button>
              <el-button @click="updatePayAnother(scope.row)" class="button button-small" size="mini" type="primary">{{scope.row.payAnother == 0 ? '禁用代付' : '启用代付'}}</el-button>
              <el-button @click="deleteMethod(scope.row)" class="button button-small" size="mini" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    </TableBody>
    </div>


    <!--编辑-->
    <el-dialog
      title="编辑支付方式"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-form :label-position="'top'" class="form-style" ref="form" :model="formAdd" label-width="100px">

        <el-form-item label="标识">
          <el-input v-model="formAdd.type" placeholder="请输入标识"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="formAdd.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formAdd.account" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item label="收款方式（801:印度-UPI）(802:印度-PAYTM)请填写数字">
          <el-input v-model="formAdd.usdt" placeholder="请输入代码"></el-input>
        </el-form-item>
        <el-form-item label="代付出款方式(IMPS或UPI)">
          <el-input v-model="formAdd.typeAnother" placeholder="请输入代付出款方式"></el-input>
        </el-form-item>
        <el-form-item label="最小金额">
          <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="formAdd.minBalance" placeholder="请输入最小金额"></el-input>
        </el-form-item>
        <el-form-item label="最大金额">
          <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="formAdd.maxBalance" placeholder="请输入最大金额"></el-input>
        </el-form-item>
        <el-form-item label="手续费">
          <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="formAdd.serviceCharge" placeholder="请输入手续费"></el-input>
          <span class="el-form-tips">通道收取的收费，如10% 填写0.1</span>
        </el-form-item>
        <el-form-item label="二维码">

        </el-form-item>
        <el-form-item label="商户秘钥">
          <el-input v-model="formAdd.secretKey" placeholder="请输入商户秘钥"></el-input>
        </el-form-item>
        <el-form-item label="允许使用级别">
          <el-checkbox-group v-model="checkedCities">
            <el-checkbox v-for="item in levelOption" :label="item" :key="item">{{item}}</el-checkbox>
          </el-checkbox-group>
          <span class="el-form-tips">不勾选，所以级别都可以使用</span>
        </el-form-item>




      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取消编辑</el-button>
    <el-button type="primary" @click="confirm">保存数据</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {baseUrl} from "@/api/url";
import {bankList, updateStatus, updatePayAnother, payUpdate, payAdd, payDelete} from '../../../api/systemApi'
import TableMixin from '@/mixins/TableMixin'
export default {
  name: 'paymentMethod',
  mixins: [TableMixin],
  data() {
    return {
      baseUrl,
      checkedCities: [],
      levelOption: ['VIP0', 'VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6', 'VIP7', 'VIP8', 'VIP9'],

      tableLoading: false,
      dialogVisible: false,
      fileList: [],
      tableData: [],
      form: {
        usdt: '',
        account: ''
      },
      formAdd: {
        type: '',
        name: '',
        account: '',
        usdt: '',
        typeAnother: '',
        minBalance: '',
        maxBalance: '',
        serviceCharge: '',
        secretKey: '',




      },

      pagination: {
        page: 1,
        size: 20
      },
      total: 0,
      type: ''


    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    add() {
      this.dialogVisible = true
      this.type = 'add'
      this.formAdd = {}
    },
    getTableList() {
      bankList({...this.pagination, ...this.form}).then(res => {
        this.tableData = res.data.result
        this.total = res.data.total
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },
    edit(row) {
      this.dialogVisible = true
      this.formAdd = JSON.parse(JSON.stringify(row))
      this.type = 'edit'

    },
    uploadSuccess(response, file, fileList) {
      this.fileList = []
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.getTableList()
    },
    deleteFunc(row) {
      payDelete({id: row.id}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    },
    // 删除
    deleteMethod(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteFunc(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    addSubmit() {
      payAdd({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    },
    editSubmit() {
      payUpdate({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    },
    confirm() {
      if (this.type == 'add') {
        this.addSubmit()
      } else {
        this.editSubmit()
      }
    },
    // 修改状态（代收状态）
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 0 ? -1 : 0
      }
      updateStatus(data).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    },
    // 修改代付状态
    updatePayAnother(row) {
      const data = {
        id: row.id,
        payAnother: row.payAnother === 0 ? -1 : 0
      }
      updatePayAnother(data).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.button-small {
  padding: 6px 12px;
}
</style>

