<template>
<div class="layui-layout-body   pace-done pace-done">
    <div class="pace  pace-inactive pace-inactive">
        <div class="pace-progress" data-progress-text="100%" data-progress="99"
            style="transform: translate3d(100%, 0px, 0px);">
            <div class="pace-progress-inner"></div>
        </div>
        <div class="pace-activity"></div>
    </div>
    <div class="layui-layout layui-layout-admin layui-layout-left-hide"><!-- 顶部菜单 开始 -->
   <!-- 主体内容 开始 -->
        <div class="layui-body layui-bg-gray">
            <div class="layui-card layui-bg-gray">

                <div class="layui-card-body layui-anim layui-anim-upbit">

                    <div class="think-box-shadow store-total-container">
                        <div class="margin-bottom-15">
                          <el-form>
                            <el-form-item label="查看代理面板">
                                <el-cascader
                                  size="mini"
                                  v-model="agentType"
                                   @change="handleChange"
                                  :options="options"
                                  :show-all-levels="false"
                                  :props="{ value: 'key',label: 'key', expandTrigger: 'hover', checkStrictly: true, emitPath: false }"
                                 >
                                </el-cascader>
                            </el-form-item>
                          </el-form>
                        </div>







                      <!--新面板-->

                      <div class="layui-row layui-col-space15" id="shop_content">
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background:linear-gradient(-125deg,#57bdbf,#2f9de2);">
                            <div style="display: flex">
                              <div>
                                <div>总充值人数</div>
                                <div>{{tableData.userTopUpCount}}</div>
                                <div>今日首冲人数 <span class="num2">{{tableData.todayFirstTopUpTotal}}</span></div>
                                <p>昨日首冲人数 <span class="num3">{{tableData.yesterdayFirstTopUpTotal}}</span></p>
                                =================
                                <div>今日充值人数 <span class="num2">{{tableData.todayUserTopUpCount}}</span></div>
                                <p>昨日充值人数 <span class="num3">{{tableData.yesterdayUserTopUpCount}}</span></p>
                              </div>
                              <img src="../../icons/svg/total.svg" style="width: 70px"/>
                            </div>

                          </div>

                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background:linear-gradient(-141deg,#ecca1b,#f39526)">
                            <div style="display: flex">
                              <div>
                                <div>总充值金额</div>
                                <div>{{tableData.userTopUpBalance}}</div>
                                <div>今日首冲金额 <span class="num2">{{tableData.todayFirstTopUpBalance}}</span></div>
                                <p>昨日首冲金额 <span class="num3">{{tableData.yesterdayFirstTopUpBalance}}</span></p>
                                =================
                                <div>今日新增充值 <span class="num2">{{tableData.todayTopUpBalance}}</span></div>
                                <p>昨日新增充值 <span class="num3">{{tableData.yesterdayTopUpBalance}}</span></p>
                              </div>
                              <img src="../../icons/svg/question.svg" style="width: 70px"/>
                            </div>

                          </div>
                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background:linear-gradient(-125deg,#ff7d7d,#fb2c95)">
                            <div style="display: flex">
                              <div>
                                <div>用户总量</div>
                                <div>{{tableData.userTotal}}</div>
                                <div>今日新增 <span class="num2">{{tableData.todayUserAdd}}</span></div>
                                <p>昨日新增 <span class="num3">{{tableData.yesterdayUserAdd}}</span></p>
                                =================
                                <div>总余额 <span class="num2">{{tableData.userTotalBalance}}</span></div>
                                <div>总收益 <span class="num3">{{tableData.userTotalIncome}}</span></div>
                              </div>
                              <img src="../../icons/svg/users.svg" style="width: 70px"/>
                            </div>
                          </div>
                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background:linear-gradient(-113deg,#c543d8,#925cc3)">
                            <div style="display: flex">
                              <div>
                                <div>订单总量（总金额）</div>
                                <div>{{tableData.orderTotal}}（{{tableData.orderTotalBalance}}）</div>
                                <div>今日做单人数 <span class="num2">{{tableData.todayGrabOrderCount}}</span></div>
                                <p>昨日做单人数 <span class="num3">{{tableData.yesterdayGrabOrderCount}}</span></p>
                                =================
                                <div>今日新增订单 <span class="num2">{{tableData.todayOrderAdd}}</span></div>
                                <p>昨日新增订单 <span class="num3">{{tableData.yesterdayOrderAdd}}</span></p>
                              </div>
                              <img src="../../icons/svg/book.svg" style="width: 70px"/>
                            </div>
                          </div>
                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background: linear-gradient(-141deg,#963064,#8a0920);">
                            <div style="display: flex">
                              <div>
                                <div>订单总佣金</div>
                                <div>{{tableData.userTotalCommission}}</div>
                                <div>抢单佣金 <span class="num2">{{tableData.userGrabCommission}}</span></div>
                                <p>下级返佣 <span class="num3">{{tableData.levelCommission}}</span></p>
                                =================
                                <div>今日新增佣金 <span class="num2">{{tableData.todayAddCommission}}</span></div>
                                <p>昨日新增佣金 <span class="num3">{{tableData.yesterdayAddCommission}}</span></p>
                              </div>
                              <img src="../../icons/svg/question1.svg" style="width: 70px"/>
                            </div>

                          </div>
                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background: linear-gradient(-141deg,#ec4b1b,#f39526);">
                            <div style="display: flex">
                              <div>
                                <div>提现人数</div>
                                <div>{{tableData.userWithdrawalCount}}</div>
                                <div>今日首提人数 <span class="num2">{{tableData.todayUserFirstWithdrawalCount}}</span></div>
                                <div>今日首提金额 <span class="num2">{{tableData.todayUserFirstWithdrawalBalance}}</span></div>
                                =================
                                <p>昨日首提人数 <span class="num3">{{tableData.yesterdayUserrFirstWithdrawalCount}}</span></p>
                                <p>昨日首提金额 <span class="num3">{{tableData.yesterdayUserFirstWithdrawalBalance}}</span></p>
                              </div>
                              <img src="../../icons/svg/money.svg" style="width: 70px"/>
                            </div>
                          </div>
                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background: linear-gradient(-141deg,#ec4b1b,#f39526);">
                            <div style="display:flex;">
                              <div>
                                <div>用户提现金额</div>
                                <div>{{tableData.userWithdrawalBalance}}</div>
                                <div>今日提现人数 <span class="num2">{{tableData.todayUserWithdrawalCount}}</span></div>
                                <div>今日提现金额 <span class="num2">{{tableData.todayUserWithdrawalBalance}}</span></div>
                                =================
                                <p>昨日提现人数 <span class="num3">{{tableData.yesterdayUserWithdrawalCount}}</span></p>
                                <p>昨日提现金额 <span class="num3">{{tableData.yesterdayUserWithdrawalBalance}}</span></p>
                              </div>
                              <img src="../../icons/svg/money.svg" style="width: 70px"/>
                            </div>
                          </div>
                        </div>
                        <div class="layui-col-sm6 layui-col-md3">
                          <div class="store-total-item nowrap"
                               style="background: linear-gradient(-141deg,#1bec78,#155623);">
                            <div style="display: flex">
                              <div>
                                <div>彩金赠送统计</div>
                                <div>{{tableData.totalLottery}}</div>
                                <div>代理赠送 <span class="num2">{{tableData.agentLottery}}</span></div>
                                <p>管理员赠送 <span class="num3">{{tableData.adminLottery}}</span></p>
                                =================
                                <div> <span class="num2"></span></div>
                                <p> <span class="num3"></span></p>
                              </div>
                              <img src="../../icons/svg/money2.svg" style="width: 70px"/>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>



                    <div  id="agent_content"  >
                      <div class="margin-bottom-15" style="background: #fff;padding: 10px">
                        <fieldset>
                          <legend>代理统计</legend>
                          <el-form
                            :model="formAdd"
                            class="layui-form layui-form-pane form-search"
                            size="mini"
                            :inline="true">
                            <el-form-item label="统计时间">
                              <el-date-picker
                                v-model="dateTime"
                                 range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="请选择统计时间">
                              </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                              <el-button @click="getList()" >搜索</el-button>
                            </el-form-item>

                          </el-form>
                        </fieldset>
                      </div>



                      <TableBody ref="tableBody">
                        <el-table
                          default-expand-all
                          row-key="account"

                          ref="table"
                          v-loading="tableLoading"
                          :data="tableDataList"
                          :header-cell-style="tableConfig.headerCellStyle"
                          :size="tableConfig.size"
                          :border="false">


                          <el-table-column  label="姓名" prop="account" align="center"></el-table-column>
                          <el-table-column  label="累计用户" prop="userTotal" align="center"></el-table-column>
                          <el-table-column  label="团队余额" prop="teamBalance" align="center"></el-table-column>
                          <el-table-column  label="今日充值" prop="todayTopup" align="center"></el-table-column>
                          <el-table-column  label="累计充值" prop="totalTopup" align="center"></el-table-column>
                          <el-table-column  label="今日提现" prop="todayWithdrawal" align="center"></el-table-column>
                          <el-table-column  label="累计提现" prop="totalWithdrawal" align="center"></el-table-column>

                        </el-table>


                      </TableBody>



                    </div>

                </div>
            </div>

        </div><!-- 主体内容 结束 -->

    </div>


</div>
</template>
<script>
import { currentDate } from '@/utils/utils'
import TableMixin from '@/mixins/TableMixin'
import '../../assets/adminAssets/files/fonts.css'
import '../../assets/adminAssets/files/layui.css'
import '../../assets/adminAssets/files/console.css'
import '../../assets/adminAssets/files/login.css'
import '../../assets/adminAssets/files/laydate.css'
import '../../assets/adminAssets/files/layer.css'
import '../../assets/adminAssets/files/code.css'
import { labelReport, agentReport, agentList } from '../../api/index'

export default {
  name: 'Department',
  mixins: [TableMixin],
  data() {
    return {
      dateTime: [],
      currentDate,
      tableData: {},
      tableDataList: [],
      formAdd: {},
      time: '',

      pagination: {
        page: 1,
        size: 10,
        total: ''
      },
      agentType: '全部',
      options: []
    }
  },
  computed: {},
  mounted() {
    this.agentList()
    this.getList()
  },
  methods: {
    // 面板
    getLabelReport() {
      const data = {
        startTime: this.dateTime.length > 0 ? this.dateTime[0] : '',
         endTime: this.dateTime.length > 0 ? this.dateTime[1] : '',
        agentType: this.agentType
      }

      labelReport(data).then(res => {
        this.tableData = res.data.result
      })
    },
    // 下拉列表
    agentList() {
      agentList().then(res => {
        this.options = res.data.result
      })
    },
    handleChange() {
      this.getList()
    },
    // 表格
    search() {
      const data = {
        startTime: this.dateTime.length > 0 ? this.dateTime[0] : '',
         endTime: this.dateTime.length > 0 ? this.dateTime[1] : '',
        agentType: this.agentType
      }
      agentReport(data).then(res => {
        if (res.data.status == 0) {
          this.tableDataList = res.data.result
        }
      })
    },
    getList() {
      this.search()
      this.getLabelReport()
    }
  }
}
</script>

<style lang="scss" scoped>

   .layui-badge {
            border-radius: 50%;
            top: 10px !important;
        }

        ::-webkit-scrollbar {
            height: 11px !important;
        }

          .layui-tab-card>.layui-tab-title .layui-this {
                        background-color: #fff;
                    }

                                         .store-total-container {
                                            // margin-left: 50px;
                            font-size: 14px;
                            margin-bottom: 20px;
                            letter-spacing: 1px;
                        }

                        .store-total-container .store-total-icon {
                            top: 45%;
                            right: 8%;
                            font-size: 65px;
                            position: absolute;
                            color: rgba(255, 255, 255, 0.4);
                        }

                        .store-total-container .store-total-item {
                            color: #fff;
                            line-height: 4em;
                            padding: 15px 25px;
                            position: relative;
                        }

                        .store-total-container .store-total-item>div:nth-child(2) {
                            font-size: 46px;
                            line-height: 46px;
                        }

                        .num2 {
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 100%
                        }

                        .store-total-container .store-total-item>div:nth-child(2) {
                            font-size: 26px;
                            line-height: 36px;
                            font-weight: bold;
                        }

                        .store-total-container .store-total-item {
                            line-height: 2em
                        }
</style>
