
<!--利息宝记录-->
<template>
  <div class="main-container">
    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">
      <!--输入框-->
      <el-form-item label="用户名称">
        <el-input v-model="form.value" placeholder="请输入用户名称"></el-input>
      </el-form-item>
      <!--输入框-->
      <el-form-item label="手机号">
        <el-input v-model="form.value" placeholder="请输入手机号"></el-input>
      </el-form-item>

      <!--选择日期-->
      <el-form-item label="存入时间">
        <el-date-picker
          v-model="form.value"
          type="date"
          placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <!--选择日期-->
      <el-form-item label="到期时间">
        <el-date-picker
          v-model="form.value"
          type="date"
          placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <!--下拉列表-->
      <el-form-item label="状态">
        <el-select v-model="form.value" placeholder="请选择">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否取出">
        <el-select v-model="form.value" placeholder="请选择">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="产品">
        <el-select v-model="form.value" placeholder="请选择">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>





      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">

          <!--排序-->
          <el-table-column label="编号" type="index" width="50"></el-table-column>

          <el-table-column  label=""UID prop="" align="center"></el-table-column>
          <el-table-column  label="手机号" prop="" align="center"></el-table-column>
          <el-table-column  label="用户" prop="" align="center"></el-table-column>
          <el-table-column  label="金额" prop="" align="center"></el-table-column>
          <el-table-column  label="预计收益" prop="" align="center"></el-table-column>
          <el-table-column  label="实际收益" prop="UID" align="center"></el-table-column>
          <el-table-column  label="存入天数" prop="UID" align="center"></el-table-column>
          <el-table-column  label="存入时间" prop="UID" align="center"></el-table-column>
          <el-table-column  label="到期时间" prop="UID" align="center"></el-table-column>
          <el-table-column  label="类型" prop="UID" align="center"></el-table-column>
          <el-table-column  label="备注" prop="UID" align="center"></el-table-column>
          <el-table-column  label="提取（手续费）" prop="UID" align="center"></el-table-column>
          <el-table-column  label="状态" prop="UID" align="center"></el-table-column>



          <!--操作-->
          <el-table-column label="操作" fixed="right" align="center" width="200">
            <template slot-scope="scope">
              --
              <!--<el-button class="button" size="mini" type="primary"></el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>

    </TableBody>
  </div>
</template>

<script>
import {} from '../../../api/index'
import TableMixin from '@/mixins/TableMixin'

export default {
  name: 'interestRecord',
  mixins: [TableMixin],
  data() {
    return {
      tableLoading: false,
      tableData: [{}],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 10,
        total: ''
      }

    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList() {},
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
    },
    handleCurrentChange(page) {
      this.pagination.page = page
    }
  }

}
</script>

<style lang="scss" scoped>
</style>

