
<!---->
<template>
  <span>
   
    <el-form-item label="一级代理">
        <el-select @change="getTopupList1" v-model="form.belongAgentLevel1" placeholder="请选择一级代理" clearable>
          <el-option
            v-for="item in belongAgentLevel1"
            :key="item.key"
            :label="item.key"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="二级代理">
        <el-select @change="getTopupList2" v-model="form.belongAgentLevel2" placeholder="请选择二级代理" clearable>
          <el-option
            v-for="item in belongAgentLevel2"
            :key="item.key"
            :label="item.key"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>

 
  </span>
</template>

<script>

import {agentList} from '../api/index'
export default {
  name: '',
  data() {
    return {
        form: {
            belongAgentLevel1: '',
            belongAgentLevel2: ''
        },
        belongAgentLevel1: [],
        belongAgentLevel2: [],
        belongAgentLevel2Ori: []

    }
  },
  mounted() {
    this.getAgentList()
  },
  methods: {
    getAgentList() {
      agentList().then(res => {
        if (res.data.status === 0) {
          res.data.result.shift()
          this.belongAgentLevel1 = res.data.result
          res.data.result.forEach(item => {
            if (item.children) {
              this.belongAgentLevel2 = this.belongAgentLevel2.concat(item.children)
              
            }
          })
          this.belongAgentLevel2Ori = JSON.parse(JSON.stringify(this.belongAgentLevel2))
        }
      })
    },
    getTopupList1() {
        if (this.form.belongAgentLevel1 !== '') {
            this.form.belongAgentLevel2 = ''
            this.belongAgentLevel2 = this.belongAgentLevel1.find(item => item.key === this.form.belongAgentLevel1).children
        } else {
            this.belongAgentLevel2 = this.belongAgentLevel2Ori
        }
      
   
      this.$emit('setAgentItem1', this.form.belongAgentLevel1)
      
    },
    getTopupList2() {
        this.$emit('setAgentItem2', this.form.belongAgentLevel2)
    }
  }

}
</script>

<style lang="scss" scoped>
</style>

