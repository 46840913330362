
<!--提现管理-->
<template>
  <div class="main-container">
    <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="状态">
        <el-select @change="getTopupList2" v-model="formInline.status" placeholder="请选择状态">
          <el-option
            v-for="item in statusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

       <!-- 一级代理、二级代理 -->
      <agent-form @setAgentItem1="setAgentItem1" @setAgentItem2="setAgentItem2"></agent-form>

      <el-form-item label="订单号">
        <el-input v-model="formInline.coNumber" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model="formInline.username" placeholder="请输入用户名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="formInline.account" placeholder="请输入手机号码"></el-input>
      </el-form-item>


        <el-form-item label="发起时间">
          <el-date-picker
            v-model="formInline.withdrawalTime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择上班时间">
          </el-date-picker>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getWithdrawalList()">搜索</el-button>
        <el-button type="primary" @click="getWithdrawalList()">导出</el-button>
      </el-form-item>
    </el-form>


    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
        >
          <el-table-column
            align="center"
            label="订单号/提现方式/代理"
            min-width="180"
            prop="coNumber"
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="用户名"
            width="120"
            prop="comment"
          />
          <el-table-column
            align="center"
            width="120"
            label="手机号"
            prop="account"
          />

          <el-table-column
            align="center"
            label="一级代理/二级代理"
            min-width="120"
            prop="belong1AndBelong2"
          >

          </el-table-column>

          <el-table-column
            align="center"
            min-width="110"
            label="累计充值/提现"
            prop="topupWithdrawalTotal"
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="提现金额"
            prop="withdrawalBalance"
          >

          </el-table-column>
          <el-table-column
            align="center"
            min-width="160"
            label="银行信息/usdt"
            prop="withdrawalType"
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="收款地址"
            width="160"
            prop="withdrawalUsdt"
          />
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="充值地址"-->
          <!--  prop=""-->
          <!--/>-->
          <el-table-column
            align="center"
            label="发起/处理/回调"
            prop="withdrawalTime"
            width="160px"
          />


          <el-table-column   align="center"
                             min-width="120"
                             label="审核状态/代理审核">
            <template slot-scope="scope">

              <span :class="{'off': scope.row.status == 0, 'on': scope.row.status == 1}">{{scope.row.status == 0 ? '待审核' : scope.row.status == -1 ? '已驳回' : '审核通过'}}</span>
            </template>
          </el-table-column>


          <el-table-column
            align="center"
            label="备注"
            prop="description"

          />
          <el-table-column
            align="center"
            label="业务员"
            prop="manager"
            width="130px"
          />

          <el-table-column   align="center"
                             width="200"
                             label="操作">
            <template slot-scope="scope">
              <el-button :disabled="!isAdmin"  @click="withdrawalverify(scope.row, '1')" v-if="scope.row.status == 0 && !isAdmin" size="mini" :style="{background: 'rgb(136,207,201)', color: 'white'}">审核通过</el-button>
              <el-button  @click="withdrawalverify(scope.row, '1')" v-if="scope.row.status == 0 && isAdmin" size="mini" :style="{background: '#009688', color: 'white'}">审核通过</el-button>
              <el-button :disabled="!isAdmin" @click="reject(scope.row)" v-if="scope.row.status == 0" size="mini" type="danger">驳回</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>



      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </TableBody>

    <el-dialog
      top="30vh"
      title="驳回"
      :visible.sync="dialogVisibleReject"
      width="25%"
    >
      <el-form ref="form" :model="rejectForm">
        <el-form-item >
          <el-input placeholder="请输入备注(可为空)" v-model="rejectForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleReject = false">取 消</el-button>
    <el-button type="primary" @click="withdrawalverify(rejectRow, '-1')">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import {withdrawalList, withdrawalverify} from '../../../api/index'
import TableMixin from '@/mixins/TableMixin'
import agentForm from '@/components/agentForm.vue'
export default {
  name: 'withdrawManagement',
  mixins: [
    TableMixin
  ],
  components: {agentForm},
  data() {
    return {
      isAdmin: true,
      rejectRow: {},
      dataList: [],
      rejectForm: {
        description: ''
      },
      dialogVisibleReject: false,
      formInline: {
        status: '',
        withdrawalTime: '',
        username: '',
        account: '',
        coNumber: '',
        belongAgentLevel1: '',
        belongAgentLevel2: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
      total: 0,
      proxyAuditOption: [{
        label: '全部',
        value: ''
      },
        {
          label: '未审核',
          value: '1'
        },
        {
          label: '已通过',
          value: '2'
        },
        {
          label: '已驳回',
          value: '3'
        }],
      statusOption: [{
        label: '全部',
        value: ''
      },
        {
          label: '待支付',
          value: '0'
        },
        {
          label: '审核通过',
          value: '1'
        },
        {
          label: '已驳回',
          value: '-1'
        }],
      primaryAgencyOption: [{
        label: '全部',
        value: ''
      }],
      confirmOption: [{
        label: '全部',
        value: ''
      },
        {
          label: '未确认',
          value: '1'
        },
        {
          label: '已确认',
          value: '2'
        }],
      channelOption: [{
        label: '全部',
        value: ''
      }],
      secondAgencyOption: [{
        label: '全部',
        value: ''
      }],

    }
  },
  created() {

  },
  mounted() {
    this.isAdmin = window.localStorage.isAdmin == '1'
    this.getWithdrawalList()

  },
  methods: {
    reject(row) {
      this.rejectRow = JSON.parse(JSON.stringify(row))
      this.dialogVisibleReject = true
      this.rejectForm = {}
    },

    // 获取会员列表
    getWithdrawalList() {
      withdrawalList({...this.pagination, ...this.formInline}).then(res => {
        this.dataList = res.data.result
        this.total = res.data.total

      })
    },
    setAgentItem1(val) {
      this.pagination.page = 1
      this.pagination.size = 10
      this.formInline.belongAgentLevel1 = val
      if (this.formInline.belongAgentLevel1 !== '') {
        this.formInline.belongAgentLevel2 = ''
      }
      this.getWithdrawalList()
    },
    setAgentItem2(val) {
      this.pagination.page = 1
      this.pagination.size = 10
      this.formInline.belongAgentLevel2 = val
      this.getWithdrawalList()
    },
    getTopupList2() {
      this.pagination.page = 1
      this.pagination.size = 10
      this.getWithdrawalList()
    },
    handleSizeChange(size) {
      this.formInline.size = size
      this.formInline.page = 1
      this.getWithdrawalList()
    },
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getWithdrawalList()
    },
    // 审核
    withdrawalverify(row, status) {
      const data = {
        id: row.id,
        account: row.account,
        description: status == '-1' ? this.rejectForm.description : '',
        status
      }
      withdrawalverify(data).then(res => {
        if (res.data.status == 0) {
          this.getWithdrawalList()
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.dialogVisibleReject = false
        }

      })
    }

  }
}
</script>

<style lang="scss" scoped>

/* 如果你只想针对某个特定的按钮去掉小手图标，可以增加一个特定的类 */
.el-button.no-pointer:hover {
  background-color: inherit;
  color: inherit;
}


.avatar-container {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  vertical-align: middle;
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .avatar-vip {
    border: 2px solid #cece1e;
  }
  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}
.gender-container {
  .gender-icon {
    width: 20px;
  }
}
</style>
