
<!--客户列表-->
<template>
  <div>
  <div v-if="notShow">无权限</div>
  <div class="main-container" v-else style="background: #fff">
    <el-button
      type="primary"
      @click="add"
      size="mini"
      style="margin: 10px; float: right">添加客服</el-button>
    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">

      <!--输入框-->
      <el-form-item label="用户名称">
        <el-input v-model="form.username" placeholder="用户名称"></el-input>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-input v-model="form.startTime" placeholder="注册时间"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">

          <!--排序-->
          <el-table-column label="ID" prop="id" width="50"></el-table-column>

          <el-table-column  label="用户名" prop="username" align="center"></el-table-column>
          <el-table-column  label="链接" prop="url" align="center"></el-table-column>
          <el-table-column  label="上班时间" prop="startTime" align="center"></el-table-column>
          <el-table-column  label="下班时间" prop="endTime" align="center"></el-table-column>
          <el-table-column  label="添加时间" prop="" align="center"></el-table-column>

          <!--操作-->
          <el-table-column label="操作" fixed="right" align="center" width="200">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" class="button btnGreen" size="mini"  >编辑</el-button>
              <el-button @click="updateStatus(scope.row)" :class="{'btnYellow': scope.row.status === 0, 'btnGreen': scope.row.status !== 0 }" class="button" size="mini">{{scope.row.status === 0 ? '禁用' : '启用'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

    </TableBody>

    <!--新增/编辑代理-->
    <el-dialog
      :title="type == 'add' ? '添加客服' : '编辑客服'"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-form :label-position="'top'" class="form-style" ref="form" :model="formAdd" label-width="100px">

        <el-form-item label="用户名称">
          <el-input v-model="formAdd.username" placeholder="请输入用户名称"></el-input>
        </el-form-item>

        <el-form-item label="链接">
          <el-input v-model="formAdd.url" placeholder="请输入链接"></el-input>
        </el-form-item>

        <el-form-item label="上班时间">
          <el-date-picker
            v-model="formAdd.startTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择上班时间">
          </el-date-picker>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取消</el-button>
    <el-button type="primary" @click="confirm">提交</el-button>
  </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
import {
  customerList,
  addCustomer,
  updateCustomer,
  updateStatus
} from '../../../api/roleApi'
import TableMixin from '@/mixins/TableMixin'

export default {
  name: 'customerList',
  mixins: [TableMixin],
  data() {
    return {
      type: '',
      tableLoading: false,
      tableData: [],
      dialogVisible: false,

      form: {
        value: ''
      },
      formAdd: {
        username: '',
        url: '',
        startTime: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
    total: 0,
      notShow: false

    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    this.getTableList()
  },
  methods: {
    getTableList() {
      const data = {...this.pagination}
      customerList(data).then(res => {
        this.tableData = res.data.result
        this.total = res.data.total
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },
    add() {
      this.formAdd = {}
      this.type = 'add'
      this.dialogVisible = true
    },
    edit(row) {
      this.formAdd = JSON.parse(JSON.stringify(row))
      this.type = 'edit'
      this.dialogVisible = true
    },

    confirm() {
      if (this.type === 'add') {
        this.addSubmit()
      } else {
        this.editSubmit()
      }
    },

    // 添加客服
    addSubmit() {
      addCustomer({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    editSubmit() {
      updateCustomer({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    // 修改状态
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 0 ? -1 : 0
      }
      updateStatus(data).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    }

  }

}
</script>

<style lang="scss" scoped>
.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}
</style>

