<template>
  <div class="vaw-nav-bar-wrapper">
    <Humburger v-if="itemClick !== 'main'"/>
    <!-- <Breadcrumb v-if="state.device !== 'mobile'" /> -->

    <!-- 顶部菜单 开始 -->
    <ul class="layui-nav layui-layout-left" style="height: 46px; line-height: 46px;display: flex; align-items: center">
      <li class="layui-nav-item" lay-unselect=""><a class="text-center" data-target-menu-type=""><i
        class="layui-icon layui-icon-spread-left"></i></a></li>
      <li class="layui-nav-item" :class="{'isClick layui-this': itemClick == 'main'}" @click="clickTab('main')">
        <a data-menu-node="m-1"
           data-open="/admin/index/main.html"><span>后台首页</span></a></li>


      <li class="layui-nav-item" :class="{'isClick layui-this': itemClick == 'role'}" @click="clickTab('role')"><a
        data-menu-node="m-62" data-open="#"><span
        class="el-icon-user padding-right-5"></span><span>角色</span></a></li>


      <li class="layui-nav-item" :class="{'isClick layui-this': itemClick == 'trad'}" @click="clickTab('trad')"><a
        data-menu-node="m-69" data-open="#"><span
        class="el-icon-money padding-right-5"></span><span>交易</span></a></li>


      <li v-if="!isHidden" class="layui-nav-item" :class="{'isClick layui-this': itemClick == 'help'}" @click="clickTab('help')">
        <a data-menu-node="m-65" data-open="#"><span
          class="el-icon-question padding-right-5"></span><span>帮助中心</span></a></li>

      <li v-if="!isHidden" class="layui-nav-item" :class="{'isClick layui-this': itemClick == 'system'}" @click="clickTab('system')">
        <a data-menu-node="m-65" data-open="#"><span
          class="el-icon-setting padding-right-5"></span><span>系统管理</span></a></li>

      <li v-if="!isHidden" class="layui-nav-item" :class="{'isClick layui-this': itemClick == 'shop'}" @click="clickTab('shop')">
        <a data-menu-node="m-65" data-open="#"><span
          class="el-icon-s-goods padding-right-5"></span><span>商城</span></a></li>
      <span
        class="layui-nav-bar" style="left: 43.5px; top: 45px; width: 0px; opacity: 0;"></span>
    </ul>

    <ul class="layui-nav layui-layout-right" style="height: 46px; line-height: 46px;display: flex; align-items: center">
      <li lay-unselect="" class="layui-nav-item" style="text-align: center"><a
        style="line-height: 24px">当地时间<br><span id="system-date">{{localTime}}</span></a></li>
      <li @click="topup" lay-unselect="" class="layui-nav-item"><a>
        充值
        <span v-if="topupNum>0" class="layui-badge recharge">{{topupNum}}</span></a></li>
      <li @click="withdrawal" lay-unselect="" class="layui-nav-item"><a>
        提现<span v-if="withdrawalNum>0" class="layui-badge deposit">{{withdrawalNum}}</span></a></li>
      <li lay-unselect="" @click="refresh" class="layui-nav-item"><a data-reload=""><i
        class="el-icon-refresh-right"></i></a></li>
      <li class="layui-nav-item">
        <dl class="layui-nav-child">
          <dd lay-unselect=""><a data-modal="/admin/index/info/10258.html"><i
            class="layui-icon layui-icon-set-fill margin-right-5"></i>基本资料</a></dd>
          <dd lay-unselect=""><a data-modal="/admin/index/pass/10258.html"><i
            class="layui-icon layui-icon-component margin-right-5"></i>安全设置</a></dd>
          <dd lay-unselect=""><a data-confirm="确定要退出登录吗？" data-load="/admin/login/out.html"><i
            class="layui-icon layui-icon-release margin-right-5"></i>退出登录</a></dd>
        </dl>
        <!--个人信息  -->
        <div class="avatar-wrapper">
          <VAWAvatar/>
        </div>

        <!-- <a>
          <span>
            <i class="el-icon-user margin-right-5"></i>阿顺阿顺 <i class="el-icon-arrow-up"></i>
          </span>
          <span class="layui-nav-more"></span>
        </a> -->


      </li>
      <span class="layui-nav-bar" style="left: 87px; top: 45px; width: 0px; opacity: 0;"></span>
    </ul>


    <!-- <el-tabs
  id="tagViewTab"
  v-model="currentTab"
  type="card"
  :class="[showHumburger ? 'tab-humburger-wrapper' : 'tab-no-humburger-wrapper']"
  @tab-click="clickTab"
  @tab-remove="removeTab"
  @contextmenu.native.prevent="onContextMenu(currentTab, $event)"
>
  <el-tab-pane
    v-for="item of state.visitedView"
    :key="item.path"
    :label="item.meta.title"
    :name="item.fullPath"
    :closable="!isAffix(item)"
  />
</el-tabs> -->


    <!-- <div style="flex: 1">
    </div> -->

    <!-- <div class="right-wrapper">
      <ActionItems />
    </div>
    <div class="avatar-wrapper">
      <VAWAvatar />
    </div> -->
  </div>
</template>

<script>
import store from '../store'
import '../../assets/adminAssets/files/fonts.css'
import '../../assets/adminAssets/files/layui.css'
import '../../assets/adminAssets/files/console.css'
import '../../assets/adminAssets/files/laydate.css'
import '../../assets/adminAssets/files/layer.css'
import '../../assets/adminAssets/files/code.css'
import {topAndWithdrawal} from '../../api/tradApi'

import Mock from 'mockjs'
import {baseData} from '../../../mock/base'
import {adminRoutes, editorRoutes} from '../../../mock/router'

import addDynamicRoute from '../../router/index'
import { currentDate } from '@/utils/utils'

export default {
  name: 'NavBar',
  data() {
    return {
      localTime: '',
      currentDate,
      withdrawalNum: 0,
      topupNum: 0,
      dialogVisible: true,
      state: store.state,
      currentTab: this.$route.fullPath,
      showHumburger: true,
      itemClick: '',
      isHidden: false
    }
  },
  beforeDestroy() {
    this.stopClock()
  },
  mounted() {
    this.isHidden = window.localStorage.isAdmin != '1'
    this.localTime = this.currentDate()
    this.startClock()
    topAndWithdrawal().then(res => {
      this.topupNum = res.data.result.topupTotal
      this.withdrawalNum = res.data.result.withdrawalTotal

    })
   this.itemClick = window.localStorage.getItem('itemClick')
  },
  methods: {
    startClock() {
      this.timer = setInterval(() => {
        this.localTime = this.currentDate()
      }, 1000)
    },
    stopClock() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    refresh() {
      location.reload()
    },
    // 点击充值
    topup() {
      this.$router.push({path: '/trad/tradManagement/rechargeManagement'})
      store.toggleCollapse(false)
      localStorage.setItem('itemClick', 'trad');
      this.$emit('clickHeaderTitle', 'trad')
      this.itemClick = 'trad'
    },
    // 点击提现
    withdrawal() {
      this.$router.push({path: '/trad/tradManagement/withdrawManagement'})
      store.toggleCollapse(false)
      localStorage.setItem('itemClick', 'trad');
      this.$emit('clickHeaderTitle', 'trad')
      this.itemClick = 'trad'
    },
// 点击上方菜单
    clickTab(name) {
      // 将上方点击的菜单名存储起来
      localStorage.setItem('itemClick', name);
      this.itemClick = name
      // 触发父组件MainLayout
      this.$emit('clickHeaderTitle', name)
      if (name === 'main') {
        store.toggleCollapse(true)
        this.$router.push({path: '/index/main'})
      } else {
        store.toggleCollapse(false)
        if (name === 'role') {
          this.$router.push({path: '/role/memberManagement/memberList'})
        }
        if (name === 'trad') {
          this.$router.push({path: '/trad/tradManagement/orderList'})
        }
        if (name === 'help') {
          this.$router.push({path: '/help/main/publicManagement'})
        }
        if (name === 'system') {
          this.$router.push({path: '/system/systemConfiguration/systemParams'})
        }
        if (name === 'shop') {
          this.$router.push({path: '/trad/shopManagement/shopClass'})
        }
      }

    },
    removeTab(name) {
      const findItem = this.state.visitedView.find((it) => it.fullPath === name)
      if (findItem) {
        store.removeVisitedView(findItem).then((_) => {
          if (this.currentTab === name) {
            this.currentTab = this.state.visitedView[
            this.state.visitedView.length - 1
              ].fullPath
            this.$router.push(this.currentTab)
          }
        })
      }
    },
    isAffix(route) {
      return route.meta && route.meta.affix
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables.scss";

.vaw-nav-bar-wrapper {
  height: $logoHeight;
  max-height: $logoHeight;
  min-height: $logoHeight;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .avatar-wrapper {
    padding-right: 15px;
  }

  .right-wrapper {
    height: 100%;
  }
}

.layui-badge {
  border-radius: 50%;
  top: 18px !important;
}

::-webkit-scrollbar {
  height: 11px !important;
}

.isClick {
  background-color: #009688;
}
</style>
