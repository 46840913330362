<!--会员列表-->

<template>
  <div class="main-container">
    <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="formInline"
             class="demo-form-inline">


      <!-- 一级代理、二级代理 -->
      <agent-form @setAgentItem1="setAgentItem1" @setAgentItem2="setAgentItem2"></agent-form>

      <el-form-item label="连单">
        <el-select v-model="formInline.region4" placeholder="请选择连单">
          <el-option
            v-for="item in primaryAgencyOption"

            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态">
        <el-select clearable @change="getTopupList2" v-model="formInline.status" placeholder="请选择状态">
          <el-option
            v-for="item in statusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序方式">
        <el-select v-model="formInline.region6" placeholder="请选择排序方式">
          <el-option
            v-for="item in sortModelOption"

            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="formInline.account" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="邀请码">
        <el-input v-model="formInline.inviteCode" placeholder="请输入邀请码"></el-input>
      </el-form-item>
      <el-form-item label="UID">
        <el-input v-model="formInline.uid" placeholder="请输入UID"></el-input>
      </el-form-item>
      <el-form-item label="IP">
        <el-input v-model="formInline.ip" placeholder="请输入IP"></el-input>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker
        @change="getTopupList2"
          v-model="formInline.registerTime"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="注册时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getMemberList">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">导出</el-button>
      </el-form-item>
      <el-form-item label="打针幅度">
        <div style="display: flex">
          <el-input placeholder="最低1.1">

          </el-input>
          <el-button style="margin-left: 10px" type="primary">批量打针</el-button>
        </div>

      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
        >
          <el-table-column
            type="selection"
            width="45"
          />
          <el-table-column
            align="center"
            label="UID"
            width="80"
            prop="uid"
          >
          </el-table-column>

          <el-table-column
            align="center"
            label="手机号"
            width="120"
            prop="account"
          >
          </el-table-column>
          <el-table-column
            align="center"
            min-width="160"
            label="注册时间"
            prop="registerTime"
          />

          <el-table-column
            align="center"
            label="最后做单时间"
            prop="grabTime"
            width="130px"
          />


          <el-table-column
            align="center"
            label="一级代理"
            prop="belongAgentLevel1"
          ></el-table-column>

          <el-table-column
            align="center"
            label="二级代理"
            prop="belongAgentLevel2"
          >
          </el-table-column>

          <el-table-column
            align="center"
            label="账号"
            prop="account"
            width="160px"
          />
          <el-table-column
            align="center"
            label="备注"
            prop="comment"
            width="160px"
          />
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="国家"-->
          <!--  prop="area"-->
          <!--  width="130px"-->
          <!--/>-->
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="抢单开关"-->
          <!--&gt;-->
          <!--  <template slot-scope="scope">-->
          <!--    <el-switch-->
          <!--      v-model="scope.row.grabSwitch"-->
          <!--      :active-value="1"-->
          <!--      :inactive-value="0"-->
          <!--    />-->
          <!--  </template>-->
          <!--</el-table-column>-->

          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="用户名"-->
          <!--  prop="username"-->

          <!--/>-->
          <el-table-column
            align="center"
            label="上级用户"
            prop="upLevelUserName"

          />

          <el-table-column
            align="center"
            label="账户余额"
            prop="balance"

          />
          <el-table-column
            align="center"
            label="佣金"
            prop="commission"

          />


          <el-table-column
            align="center"
            label="下级佣金"
            prop="levelCommission"

          />
          <el-table-column
            align="center"
            label="累计充值金额"
            prop="topUpTotalBalance"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计充值次数"
            prop="topUpTotalCount"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计提现金额"
            prop="withdrawalTotalBalance"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计提现次数"
            prop="withdrawalTotalCount"
            width="130px"
          />


          <el-table-column
            align="center"
            label="冻结金额"
            prop="freezeAmount"
            width="130px"
          />
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="上级用户"-->
          <!--  prop="upLevelUserName"-->
          <!--  width="130px"-->
          <!--/>-->
          <el-table-column
            align="center"
            label="邀请码"
            prop="inviteCode"
            width="130px"
          />


          <el-table-column
            align="center"
            label="最后登录时间"
            prop="loginTime"
            width="200px"
          />
          <el-table-column
            align="center"
            label="最后登录ip"
            prop="ip"
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="注册ip"
            prop="registerIp"
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="状态"
            prop=""
            width="130px"
          >
            <template slot-scope="scope">
              <span :class="{'on': scope.row.status == '0', 'off': scope.row.status == '-1'}">
                 {{ scope.row.status == '0' ? '正常' : '禁用' }}
              </span>

            </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--  align="center"-->
          <!--  label="备注"-->
          <!--  prop="comment"-->
          <!--  width="130px"-->
          <!--/>-->
          <el-table-column
            align="center"
            width="280"
            label="操作"
            fixed="right"

          >
            <template slot-scope="scope">
              <el-button class="button-small btnGreen" @click="edit(scope.row)" size="mini">编辑</el-button>
              <el-button class="button-small btnYellow" size="mini">公告</el-button>
              <el-button class="button-small" size="mini" type="primary">账变</el-button>
              <el-button class="button-small" size="mini" type="danger">打针</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog
          title="编辑"
          :visible.sync="dialogVisible"
          width="70%"
        >
          <el-form  class="form-style" ref="form" :model="form" label-width="100px">
            <el-form-item label="账户余额:">
              <el-input oninput="value=value.replace(/[^\-?\d.]/g,'')" placeholder="请输入账户余额"
                        v-model="form.balance"></el-input>
            </el-form-item>
            <el-form-item label="密码:">
              <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="提现密码:">
              <el-input type="password" v-model="form.withdrawalPassword" placeholder="请输入提现密码"></el-input>
            </el-form-item>
            <el-form-item label="冻结金额:">
              <el-input oninput="value=value.replace(/[^\-?\d.]/g,'')" placeholder="请输入冻结金额"
                        v-model="form.freezeAmount"></el-input>
            </el-form-item>
            <el-form-item label="所属代理" v-if="!notShow">
              <el-select clearable v-model="form.agentType" placeholder="请选择所属代理">
                <el-option
                  v-for="item in levelOption"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="备注:">
              <el-input v-model="form.comment" placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-form-item label="禁用开关:">
              <el-switch
                v-model="form.status"
                :active-value="0"
                :inactive-value="-1"
              />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEdit(form)">确 定</el-button>
  </span>
        </el-dialog>

      </template>


      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>


    </TableBody>
  </div>
</template>

<script>
import {memberList, memberEdit, levelAgent} from '../../../api/index'
import TableMixin from '@/mixins/TableMixin'
import agentForm from '@/components/agentForm.vue'
import md5 from 'crypto-js/md5';

export default {
  name: 'memberList',
  mixins: [TableMixin],
  components: {agentForm},
  data() {
    return {
      dialogVisible: false,
      form: {
        balance: 0,
        freezeAmount: 0,
        password: '',
        withdrawalPassword: '',
        comment: '',
        agentType: '',
        status: ''

      },
      formInline: {

        inviteCode: '',
        uid: '',
        account: '',
        belongAgentLevel1: '',
        belongAgentLevel2: '',
        status: '',
        registerTime: '',
        ip: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
      statusOption: [
        {
        label: '正常',
        value: '0'
      },
      {
        label: '禁用',
        value: '-1'
      }
      ],
      total: 0,
      primaryAgencyOption: [{
        label: '全部',
        value: ''
      }],
      sortModelOption: [{
        label: '充值金额倒序',
        value: ''
      },
        {
          label: '充值次序倒序',
          value: ''
        },
        {
          label: '提现金额倒序',
          value: ''
        },
        {
          label: '提现次数倒序',
          value: ''
        }],
      levelOption: [],
      notShow: false
    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    this.getMemberList()
    this.levelAgent()

  },
  methods: {
    setmd5(val) {
      if (val) {
        return md5(val).toString()
      } else {
        return ''
      }

    },
    // 获取所属代理
    levelAgent() {
      levelAgent().then(res => {
        if (res.data.status === 0) {
          this.levelOption = res.data.result
        }
      })
    },
    setAgentItem1(val) {
      this.pagination.page = 1
      this.pagination.size = 20
      this.formInline.belongAgentLevel1 = val
      if (this.formInline.belongAgentLevel1 !== '') {
        this.formInline.belongAgentLevel2 = ''
      }
      this.getMemberList()
    },
    setAgentItem2(val) {
      this.pagination.page = 1
      this.pagination.size = 20
      this.formInline.belongAgentLevel2 = val
      this.getMemberList()
    },
        getTopupList2() {
      this.pagination.page = 1
      this.pagination.size = 20
      this.getMemberList()
    },
    edit(row) {
      this.form.comment = row.comment
      this.form.account = row.account
      this.form.status = row.status
      this.form.agentType = row.belongAgentLevel2
      this.form.password = ''
      this.form.withdrawalPassword = ''
      this.form.balance = 0
      this.form.freezeAmount = 0

      this.dialogVisible = true
    },

    confirmEdit() {

      const data = {
        account: this.form.account,
        balance: Number(this.form.balance),
        status: this.form.status,
        comment: this.form.comment,
        password: this.setmd5(this.form.password),
        withdrawalPassword: this.setmd5(this.form.withdrawalPassword),
        freezeAmount: this.form.freezeAmount
      }
      if (!this.notShow) {
        data.agentType = this.form.agentType
      }
      if ((this.form.password && this.form.password.length < 6) || (this.form.withdrawalPassword && this.form.withdrawalPassword.length < 6)) {
        this.$errorMsg('密码需要六位及以上')
        return
      }
      memberEdit(data).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getMemberList()
        }
      })
    },

    // 获取会员列表
    getMemberList() {
      memberList({...this.pagination, ...this.formInline}).then(res => {
        this.dataList = res.data.result
        this.total = res.data.total

      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.formInline.page = 1
      this.getMemberList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getMemberList()
    }
  }

}
</script>
<style lang="scss" scoped>

.serviceManage-page {
  height: 48.5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 24px;
}


.avatar-container {
  position: relative;
  width: 30px;
  margin: 0 auto;
  vertical-align: middle;

  .avatar {
    width: 100%;
    border-radius: 50%;
  }

  .avatar-vip {
    border: 2px solid #cece1e;
  }

  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}

.gender-container {
  .gender-icon {
    width: 20px;
  }
}

.button-small {
  padding: 6px 12px;
}

.btnGreen {
  background: #009688;
  color: white
}

.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}

.el-table__body-wrapper::-webkit-scrollbar {
  height: 20px;
  width: 20px;
}

::-webkit-scrollbar {
  height: 20px;
  width: 20px;
}

.el-table__body-wrapper::-webkit-scrollbar {
  width: 12px !important;
  height: 12px;

}


.el-table__body-wrapper::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  width: 20px; //垂直方向
  height: 20px !important; //水平方向
}

.el-table__body-wrapper::-webkit-scrollbar {
  width: 20px; //垂直方向
  height: 20px !important; //水平方向
}

</style>
