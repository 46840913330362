<template>
  <div class="main-container">

    <el-form :model="formInline" style="background: white;padding: 20px 20px 0" size="mini" :inline="true"
             class="demo-form-inline">
      <!--下拉列表-->
      <el-form-item label="订单状态">
        <el-select v-model="formInline.value" placeholder="订单状态">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <!--下拉列表-->
      <el-form-item label="支付状态">
        <el-select v-model="formInline.value1" placeholder="支付状态">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input v-model="formInline.ubNumber"></el-input>
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model="formInline.username"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="formInline.account"></el-input>
      </el-form-item>

      <el-form-item label="下单时间">
        <el-date-picker
          v-model="formInline.bookTime"
          type="date"
          placeholder="请选择下单时间">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>
    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="tableConfig.border"
        >

          <el-table-column
            align="center"
            label="订单号"
            prop="ubNumber"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="用户名"
            width="120"
            prop="comment"
          />
          <el-table-column
            align="center"
            label="手机号"
            width="120"
            prop="account"
          />
          <!--新建-->
          <el-table-column
            align="center"
            label="账户余额"
            prop="balance"
          >
          </el-table-column>
          <!--新加-->
          <el-table-column
            align="center"
            min-width="130"
            label="基础金额/冻结金额"
            prop="balanceFreeze"
          >
          </el-table-column>
          <!--新加-->
          <el-table-column
            align="center"
            label="需补"
            prop="needTopUp"
          >
          </el-table-column>
          <!--新加-->
          <el-table-column
            align="center"
            label="订单价格"
            prop="totalMoney"
          >
          </el-table-column>

          <el-table-column
            align="center"
            label="佣金"
            prop="goodsCommission"

          />
          <!--新加-->
          <el-table-column
            align="center"
            label="级别/连单ID"
            prop="uid"
            width="90px"
          />

          <el-table-column
            align="center"
            label="下单时间/付款时间"
            prop="bookTime"
            min-width="160"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="冻结时间"
            prop="bookTime"
            width="160px"
          />

          <el-table-column
            align="center"
            label="是否付款"
            prop="lastLoginIp"
            width="130px">
            <template slot-scope="scope">
              <span :class="{'off': scope.row.status == 0, 'on': scope.row.status !== 0}">{{scope.row.status == 0 || scope.row.status == -1 ? '待支付' : (scope.row.status == 1 ? '已支付' : '强制付款')}}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="订单状态"
            prop="lastLoginIp"
            width="130px">
            <template slot-scope="scope">
              <span>{{scope.row.status == 1 || scope.row.status == 2 ? '已完成' : (scope.row.status == -1 ? '已冻结' : '未完成')}}</span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="操作"
            width="220"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button @click="force(scope.row)" v-if="scope.row.status == 0" size="mini" class="btnGreen button-small">强制付款</el-button>
              <el-button @click="freeze(scope.row, -1)" v-if="scope.row.status == 0" size="mini" class="button-small" type="danger">冻结</el-button>
              <el-button @click="freeze(scope.row, 0)" v-if="scope.row.status == -1" size="mini" class="button-small" type="danger">解除冻结</el-button>
              <el-button @click="deleteOrder(scope.row)" size="mini" class="btnYellow button-small">删除</el-button>

            </template>
          </el-table-column>
        </el-table>
      </template>
    </TableBody>
    <!-- 分页区域 -->
    <div class="serviceManage-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.page"
        :page-size="pagination.size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TableMixin from '@/mixins/TableMixin'
import {orderList, orderFreeze, orderDelete, orderForcePay} from '../../../api/tradApi'
export default {
  name: 'orderList',
  mixins: [
    TableMixin
  ],
  data() {
    return {
      dataList: [],
      pagination: {
        page: 1,
        size: 20
      },
      total: 0,
      option: [
        {
          label: '全部',
          value: ''
        }
      ],
      formInline: {
        ubNumber: '',
        account: '',
        username: ''
      }
    }
  },
  computed: {

  },
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList() {
      orderList({...this.pagination, ...this.formInline}).then(res => {
        this.dataList = res.data.result
        this.total = res.data.total

      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },
    force(row) {
      orderForcePay({ubNumber: row.ubNumber}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }

      })
    },
    freeze(row, status) {
      orderFreeze({ubNumber: row.ubNumber, status}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }

      })
    },
    deleteOrder(row) {
      orderDelete({ubNumber: row.ubNumber}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-container {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  vertical-align: middle;

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .avatar-vip {
    border: 2px solid #cece1e;
  }

  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}

.gender-container {
  .gender-icon {
    width: 20px;
  }
}

.button-small {
  padding: 6px 12px;
}
.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}
</style>
