<!--会员等级-->


<template>
  <div>
    <div v-if="notShow">无权限</div>
    <div class="main-container" v-else>
      <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="form" class="demo-form-inline">

        <el-form-item label="UID">
          <el-input v-model="form.uid" placeholder="UID"></el-input>
        </el-form-item>
        <el-form-item label="开户人">
          <el-input v-model="form.username" placeholder="开户人"></el-input>
        </el-form-item>
        <el-form-item label="卡号">
          <el-input v-model="form.cardNumber" placeholder="卡号"></el-input>
        </el-form-item>
        <el-form-item label="CPF/IFSC">
          <el-input v-model="form.cpfIFSC" placeholder="CPF/IFSC"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getTableList()">搜索</el-button>
        </el-form-item>


      </el-form>

      <TableBody ref="tableBody">
        <template>
          <el-table
            ref="table"
            v-loading="tableLoading"
            :data="tableData"
            :header-cell-style="tableConfig.headerCellStyle"
            :size="tableConfig.size"
            :stripe="tableConfig.stripe"
            :border="false"
            @selection-change="handleSelectionChange"
          >


            <el-table-column
              align="center"
              label="UID"

              prop="uid"
            >
            </el-table-column>

            <el-table-column
              align="center"
              label="银行"
              prop="bank"

            />
            <el-table-column
              align="center"
              label="开户人"
              prop="username"

            />
            <el-table-column
              align="center"
              label="卡号"
              prop="cardNumber"
              width="170px"
            />
            <el-table-column
              align="center"
              label="手机号"
              prop=" "
              width="140px"
            />
            <el-table-column
              align="center"
              label="CPF/IFSC"
              prop="cpfIFSC"

            />
            <el-table-column
              align="center"
              width="140"
              label="操作"
              fixed="right"

            >
              <template slot-scope="scope">

                <el-button class="button btnGreen" size="mini"  @click="edit(scope.row)">编辑</el-button>


              </template>
            </el-table-column>
          </el-table>



        </template>


        <!-- 分页区域 -->
        <div class="serviceManage-page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.size"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>


      </TableBody>

      <!--编辑-->
      <el-dialog
        title="编辑银行卡"
        :visible.sync="dialogVisible"
        @close="closeDialog"
        width="70%"
      >
        <el-form class="form-style" ref="formAdd" :model="formAdd" label-width="100px">
          <el-form-item label="开户人" prop="username">
            <el-input v-model="formAdd.username"></el-input>
          </el-form-item>

          <el-form-item label="开户银行" prop="bank">
            <el-input v-model="formAdd.bank"></el-input>
          </el-form-item>

          <el-form-item label="银行类型" prop="price1">
            <el-input v-model="formAdd.price1"></el-input>
          </el-form-item>
          <el-form-item label="银行代码" prop="price2">
            <el-input v-model="formAdd.price2"></el-input>
          </el-form-item>
          <el-form-item label="开户电话" prop="price3">
            <el-input v-model="formAdd.price3"></el-input>
          </el-form-item>
          <el-form-item label="银行卡号" prop="cardNumber">
            <el-input v-model="formAdd.cardNumber"></el-input>
          </el-form-item>
          <el-form-item label="支付代码" prop="price4">
            <el-input v-model="formAdd.price4"></el-input>
          </el-form-item>
          <el-form-item label="开户地址" prop="price5">
            <el-input v-model="formAdd.price5"></el-input>
          </el-form-item>
          <el-form-item label="CPF/IFSC" prop="cpfIFSC">
            <el-input v-model="formAdd.cpfIFSC"></el-input>
          </el-form-item>
          <el-form-item label="数字账户" prop="price6">
            <el-input v-model="formAdd.price6"></el-input>
          </el-form-item>
          <el-form-item label="钱包手机号" prop="price7">
            <el-input v-model="formAdd.price7"></el-input>
          </el-form-item>
          <el-form-item label="钱包账户" prop="price8">
            <el-input v-model="formAdd.price8"></el-input>
          </el-form-item>
          <el-form-item label="钱包类型" prop="price9">
            <el-input v-model="formAdd.price9"></el-input>
          </el-form-item>


        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editSubmit">确 定</el-button>
  </span>
      </el-dialog>
    </div>
  </div>

</template>

<script>
import {bankList, updateBankList} from '../../../api/roleApi'
import TableMixin from '@/mixins/TableMixin'
export default {
  name: 'bankCard',
  mixins: [TableMixin],
  data() {
    return {
      form: {
        uid: '',
        username: '',
        cardNumber: '',
        cpfIFSC: ''
      },
      tableData: [],
      dialogVisible: false,
      pagination: {
        page: 1,
        size: 10
      },
      total: 0,
      formAdd: {

      },
      notShow: false
    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'

this.getTableList()
  },
  methods: {
    closeDialog() {
      this.$refs.formAdd.resetFields(); // 清空表单
    },
    getTableList() {
      const data = {...this.pagination,...this.form}
      bankList(data).then(res => {
        if (res.data.status === 0) {
          this.tableData = res.data.result
          this.total = res.data.total
        }
      })
    },

    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()

    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()

    },
    edit(row) {

      this.dialogVisible = true

      this.formAdd = JSON.parse(JSON.stringify(row))


    },
    //编辑商品
    editSubmit() {
      updateBankList({...this.formAdd}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '更新成功',
            type: 'success'
          })
          this.getTableList()
        } else {
          this.$message({
            message: '失败',
            type: 'error'
          })
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.btnGreen {
  background: #009688;
  color: white
}

</style>

