
<!--代理列表-->
<template>
  <div>
    <div v-if="notShow">无权限</div>
    <div class="main-container" v-else style="background: #fff"></div>
  </div>
</template>

<script>
import {agentList, agentInsert, agentUpdate, agentUpdatePwd, agentUpdateStatus} from '../../../api/roleApi'
import TableMixin from '@/mixins/TableMixin'
export default {
  name: 'agencyList',
  mixins: [TableMixin],
  data() {
    return {
      type: '',
      tableLoading: false,
      tableData: [],
      option: [
        {
          label: '平台',
          value: '平台'
        }
      ],
      form: {
        account: '',
        phone: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
      total: 0,
      dialogVisible: false,
      dialogVisiblePwd: false,
      formAdd: {
        agentType: '',
        phone: '',
        customerLink: '',
        account: '',
        email: '',
        domain: ''
      },
      formPwd: {
        id: '',
        password: '',
        confirmPassword: ''
      },
      notShow: false

    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    this.getTableList()
  },
  methods: {
    getTableList() {
      agentList({...this.pagination, ...this.form}).then(res => {
        this.tableData = res.data.result
        this.total = res.data.total
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },
    add() {
      this.type = 'add'
      this.dialogVisible = true
    },
    edit(row) {
      this.type = 'edit'
      this.formAdd = JSON.parse(JSON.stringify(row))
      this.formAdd.id = row.id
      this.dialogVisible = true
    },
    addSubmit() {
      agentInsert({...this.formAdd}).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    editSubmit() {
      agentUpdate({...this.formAdd}).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    confirm() {
      if (this.type === 'add') {
        this.addSubmit()
      } else {
        this.editSubmit()
      }
    },
    // 编辑密码
    editPwd(row) {
      this.formPwd = {}
      this.dialogVisiblePwd = true
      this.formPwd.id = row.id
    },
    // 确认修改Miami
    confirmPwd() {
      agentUpdatePwd({...this.formPwd}).then(res => {
        if (res.data.status == 0) {
          this.dialogVisiblePwd = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    // 修改状态
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 0 ? -1 : 0
      }
      agentUpdateStatus(data).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.button-small {
  padding: 6px 12px;
}
.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}


</style>

